import React from "react";
import CustomLink from "../customLink/CustomLink";

import { StyledLogoWrapper } from "./styledLogo";

import logo from "../../../assets/images/logo-inverson.svg";

const Logo = () => {
  return (
    <CustomLink to="/">
      <StyledLogoWrapper>
        <img src={logo} alt="itlab-logo" />
      </StyledLogoWrapper>
    </CustomLink>
  );
};

export default Logo;