import React, { useContext } from "react";
import { css } from "@emotion/react";
import { MoonLoader } from "react-spinners";

import { AppContext } from "../../../App";

const Spinner = ({ size, color, display, margin }) => {
  const { theme } = useContext(AppContext);
  const { themeMode } = theme;

  const defaultColor = themeMode.mainColor;
  const override = css`
    display: ${display || "block"};
    margin: ${margin || "15px auto"};
  `;

  return (
    <MoonLoader css={override} size={size} color={color ?? defaultColor} loading={true} />
  );
};

export default Spinner;