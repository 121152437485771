import { navbar } from "../../../rbac-consts";

const sidebarRoutes = [
  {
    title: "account",
    path: "/panel/account",
    perform: navbar.ACCOUNT,
    icon: "user"
  }
];

export default sidebarRoutes;