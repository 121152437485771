import { createGlobalStyle } from "styled-components";

const mainFont = "Noto Sans, sans-serif";
const mainFontSize = "14px";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: ${({ theme }) => theme.text};
    font-family: ${mainFont};
    font-size: ${mainFontSize};
    background: ${({ theme }) => theme.bodyBackgroundColor};
  }

  h1, h2, h3, h4, h5, h6, p, span, select, input {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }

  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration {
    display: none;
  }
  input {
    border: 1px solid rgba(0,0,0,0.5);
  }

  button {
    padding: 0;
    font: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover, a:focus, a:active {
    text-decoration: none;
  }
  
  .default-link {
    color: ${({ theme }) => theme.mainColor};
    &:hover {
      text-decoration: underline;
    }
  }

  //Progressbar custom style
  #nprogress .bar {
    height: 3px;
    background: ${({ theme }) => theme.mainColor};
    z-index: 1999;
  }
  #nprogress .peg {
    box-shadow: ${({ theme }) => `0 0 10px ${theme.mainColor}, 0 0 5px ${theme.mainColor}`};
  }
  #nprogress .spinner-icon {
    display: none;
    border-top-color: ${({ theme }) => theme.mainColor};
    border-left-color: ${({ theme }) => theme.mainColor};
  }

  //AntDesign dialog window
  .default-modal-wrapper {
    .rc-dialog-close {
      color: #656565;
      font-size: 14px;
      text-shadow: none;
      top: 20px;
      right: 15px;
      outline: none;
      z-index: 1;
      transition: all .3s ease;

      &-x:after {
        content: "\\e914";
        font-size: 12px;
        font-family: 'default-icons', serif;
      }
    }
    .rc-dialog-content {
      background: ${({ theme }) => theme.bodyBackgroundColor};
    }
    .rc-dialog-header {
      padding: 15px;
      background: ${({ theme }) => theme.bodyBackgroundColor};
      border: none;

      .rc-dialog-title {
        color: #656565;
        font-size: 16px;
        font-weight: 600;
        position: relative;

        &:after {
          content: "";
          width: 100%;
          height: 1px;
          background-color: #F1F1F1;
          position: absolute;
          bottom: -15px;
          left: 0;
        }
      }
    }
    .rc-dialog-body {
      padding: 15px;
    }
    .default-modal {
      top: 100px;

      &__content {
        p {
          &:not(:last-child) {
            padding-bottom: 15px;
          }
        }
      }

      &__footer {
        margin-top: 15px;
        padding-top: 15px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(75px, max-content));
        grid-gap: 15px;
        justify-content: end;
        border-top: 1px solid #F1F1F1;
      }
    }
  }

  .default-modal-wrapper_confirm {
    .rc-dialog-close {
      display: none;
    }

    .confirm-modal {
      max-width: 400px;
      margin: 10px auto;

      @media screen and (max-width: 576px) {
        max-width: inherit;
        margin: 10px;
      }
    }
  }

  //AntDesign notifications
  .rc-notification {
    padding: 0;
    transform: translateX(-50%);
    z-index: 1055;
    @media screen and (max-width: 576px) {
      width: 100%;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: inherit;
      left: 0 !important;
    }

    .rc-notification-notice-close {
      color: black;
      opacity: .5;

      &:hover {
        opacity: 1;
      }
    }

    .rc-notification-notice {
      margin: 0;
      padding: 5px 20px 0 0;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
    }
    .message-with-icon {
      min-height: 50px;
      padding: 15px 15px 15px 55px;
      color: #202020;
      display: inline-flex;
      border-radius: 5px;
      position: relative;
      &:before {
        width: 25px;
        height: 25px;
        font-size: 22px;
        font-family: 'default-icons', serif;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        top: 14px;
        left: 15px;
      }
    }
    .message-with-icon.success {
      background-color: #e3f7e4;
      &:before {
        content: '\\e922';
        color: #4fba6e;
      }
    }
    .message-with-icon.error {
      background-color: #FFECEE;
      &:before {
        content: '\\e908';
        color: #FE6A74;
      }
    }
    .message-with-icon.info {
      background-color: #e6f7ff;
      &:before {
        content: '\\e942';
        color: #3286d9;
      }
    }
    .message-with-icon.warning {
      background-color: #fdf3c9;
      &:before {
        content: '\\e97c';
        color: #efc100;
      }
    }
  }

  //AntDesign custom tabs
  .rc-tabs-dropdown {
    color: ${({theme}) => theme.mainColor};
    background-color: rgba(160, 150, 130, 0.15);
    border: 1px solid ${({theme}) => theme.mainColor};
  }

  .default-tabs {
    border: none;
    grid-gap: 20px;

    .rc-tabs-nav-more {
      display: none;
    }
    .rc-tabs-nav-list {
      .rc-tabs-tab {
        font-size: 14px;
        &:hover {
          opacity: 1;
        }
        .rc-tabs-tab-btn {
          outline: none;
        }
      }
      .rc-tabs-tab-active {
        opacity: 1;
      }
      .rc-tabs-ink-bar {
        background: ${({theme}) => theme.mainColor};
      }
      @media screen and (max-width: 576px) {
        display: grid;
        grid-template-rows: auto;
        .rc-tabs-tab {
          margin: 0px;
          padding: 5px 15px;
        }
        .rc-tabs-ink-bar {
          display: none;
        }
        .rc-tabs-tab-active {
          color: ${({theme}) => theme.mainColor};
          font-weight: 700;
          background-color: rgba(160, 150, 130, 0.15);
          border: 1px solid ${({theme}) => theme.mainColor};
          border-radius: 5px;
        }
      }
    }
    .rc-tabs-content-holder {
      .rc-tabs-tabpane {
        outline: none;
      }
    }
    @media screen and (max-width: 992px) {
      grid-template-columns: 100%;
      grid-template-rows: repeat(2, auto);
    }
  }

  .default-tabs-left {
    display: grid;
    grid-template-columns: 256px auto;
    .rc-tabs-tab {
      margin: 0px;
      padding: 5px 15px;
    }
    .rc-tabs-ink-bar {
      display: none;
    }
    .rc-tabs-tab-active {
      color: ${({theme}) => theme.mainColor};
      font-weight: 700;
      background-color: rgba(160, 150, 130, 0.15);
      border: 1px solid ${({theme}) => theme.mainColor};
      border-radius: 5px;
    }
    @media screen and (max-width: 992px) {
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }
  }

  .default-tabs-top {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 55px auto;
    overflow: inherit;
    .rc-tabs-nav-list {
      width: 100%;
      border-bottom: 1px solid #E5E5E5;
    }
    .rc-tabs-tab {
      margin-right: 20px;
      padding: 15px 0;
      background: transparent;
      opacity: 0.4;
      &:last-child {
        margin: 0;
      }
    }
    .rc-tabs-tab-active {
      color: ${({theme}) => theme.mainColor};
    }
    .rc-tabs-nav-more {
      min-width: 25px;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 100%;
      grid-template-rows: minmax(40px, auto) auto;
    }
  }

  //AntDesign custom submenu 
  .card-submenu {
    background-color: #595959;
    border-color: #595959;
    box-shadow: none;

    .rc-dropdown-menu-item {
      padding: 0;
      a, button {
        width: 100%;
        padding: 7px 10px;
        color: #fff;
        font-weight: 700;
        display: inline-grid;
        grid-template-columns: 14px max-content;
        grid-gap: 10px;
        text-align: left;
        border: none;
        outline: none;
        span {
          font-size: 14px;
          opacity: 0.5;
        }
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }

    .rc-dropdown-menu-item-selected {
      background-color: transparent;
      &:after {
        display: none;
      }
    }
  }

  //AntDesign select
  .rc-select-item-empty {
    padding: 16px 22px;
  }
  .rc-select-selector {
    min-height: 50px;
    .rc-select-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      &-item {
        display: inline-flex;
      }
    }
    span.rc-select-selection-item, span.rc-select-selection-placeholder {
      padding: 10px 22px;
      display: flex;
    }
  }
  .rc-select-dropdown {
    border: 1px solid ${({theme}) => theme.select.borderColor};
    box-shadow: 0 1px 8px rgb(0 0 0 / 20%);
    .rc-select-item-option {
      padding: 8px 22px;
      background: ${({theme}) => theme.select.backgroundColor};
      cursor: pointer;
      &:hover {
        color: #fff;
        background: ${({theme}) => theme.mainColor};
      }
      .option-select-item {
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        span {
          padding-right: 20px;
          font-size: 22px;
        }
        img {
          height: 22px;
          width: 22px;
          margin-right: 20px;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    .rc-select-item-option-selected {
      color: #fff;
      background: ${({theme}) => theme.select.backgroundColorSelected};
      .rc-select-item-option-state {
        display: none;
      }
      
    }
  }

  //AntDesign custom checkbox
  .default-checkbox {
    input {
      width: 17px;
      height: 17px;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .rc-checkbox-inner {
      width: 17px;
      height: 17px;
      border-color: transparent;
      background-color: #fff;
      box-shadow: 0 1px 8px rgb(0 0 0 / 30%);
      &:after {
        border: none
      }
    }
  }

  .rc-checkbox-checked {
    .rc-checkbox-inner {
      background-color: ${({ theme }) => theme.mainColor};

      &:after {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
      }
    }
  }

  //AntDesign custom pagination 
  .default-pagination {
    padding: 30px 0;
    display: flex;
    justify-content: center;

    .rc-pagination-item, .rc-pagination-item-link {
      color: ${({theme}) => theme.mainColor};
      background-color: transparent;
      border: 1px solid ${({theme}) => theme.mainColor};
      border-radius: 5px;
      outline: none;
      a {
        color: ${({theme}) => theme.mainColor};
      }
      &:hover {
        color: ${({theme}) => theme.mainColor};
        border: 1px solid ${({theme}) => theme.mainColor};
        background-color: rgba(160, 150, 130, 0.15);
        a {
          color: ${({theme}) => theme.mainColor};
        }
      }
    }

    .rc-pagination-item-active {
      color: #fff;
      background: ${({theme}) => theme.mainColor};
      border: 1px solid ${({theme}) => theme.mainColor};
      border-radius: 5px;
      a {
        color: #fff;
      }
      &:hover a {
        color: #fff;
      }
    }
  }

  //AntDesign drawer custom style
  .drawer.drawer-open .drawer-mask {
    opacity: 0.45;
  }

  .drawer-left .drawer-content {
    background-color: #323231;
  }

  .drawer-right .drawer-content {
    background-color: #323231;
  }
`;