import React, { createContext, Suspense, useEffect, useState } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import Header from "./components/elements/header/Header";
import AutoLogoutModal from "./components/elements/modal/AutoLogoutModal";
import PageSpinner from "./components/elements/spinner/PageSpinner";
import { getExpirationDate, isExpired } from "./utils/checkExpiredToken";
import userAuthenticationConfig from "./utils/userAuthenticationConfig";
import getUserInfo from "./utils/getUserInfo";
import eventBus from "./utils/eventBus";

import LoadRoute from "./components/loadRouter/LoadRoute";
import routes from "./routes/routes";
import userRoutesConcat from "./routes/userRoutes";

import "./i18n";
import i18n from "i18next";
import { defaultLanguage, helmetHtmlByLang, supportedLanguages, themeColor } from "./utils/consts";

import { useDarkMode } from "./utils/useDarkMode";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "./components/styles/theme/theme";

import { GlobalStyle } from "./components/styles/globalStyle";
import "./assets/fonts/default-icons/style.css";
import "./assets/css/rc-select.css";
import "rc-dialog/assets/index.css";
import "rc-notification/assets/index.css";
import "rc-dropdown/assets/index.css";
import "rc-checkbox/assets/index.css";
import "rc-switch/assets/index.css";
import "rc-pagination/assets/index.css";
import "rc-tabs/assets/index.css";
import "rc-drawer/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import http from "./utils/https";

export const AppContext = createContext({});

function App () {

  window.localStorage.removeItem("appLang");

  const history = useHistory();

  const [locale, setLocale] = useState("");
  const [visible, setVisible] = useState(false);
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("token") && !isExpired(getExpirationDate(localStorage.getItem("token"))));

  const [theme, themeToggler, mountedComponent] = useDarkMode();

  const currentUrl = new URL(document.location);
  let lngFromUrl = defaultLanguage;

  Object.keys(supportedLanguages).forEach(language => {
    let matches = currentUrl.href.match(`(^${currentUrl.origin.replaceAll("/", "\\/")}${supportedLanguages[language].regex})`);
    if (i18n.options.supportedLngs.includes(language) && matches && matches[0] !== "" && matches[1] !== '') {
      lngFromUrl = language
    }
  })

  let baseNameLang = lngFromUrl === defaultLanguage ? "/" : supportedLanguages[lngFromUrl].url;

  useEffect(() => {
    i18n.on("languageChanged", (lng) => {
      let storageLang = window.localStorage.getItem("appLang");
      let currentUlr = new URL(document.location);

      let tmp = currentUlr.href.replace(currentUlr.origin, "");

      let test = storageLang ? storageLang : (lng === defaultLanguage ? "" : lng);

      if (test !== "") {
        let reg = new RegExp(supportedLanguages[test].regex);
        tmp = tmp.replace(reg, "");
      }

      if (defaultLanguage !== lng) {
        baseNameLang = "/" + lng;
        setLocale(baseNameLang)
        history.push(supportedLanguages[lng].url + tmp);
      } else if (storageLang) {
        baseNameLang = "";
        setLocale(baseNameLang)
        history.push(tmp);
      }
    });

    return () => {
      i18n.off("languageChanged");
    };
  }, []);

  const isValidToken = () => {
    http.post("/api/is-valid-token", {}, userAuthenticationConfig(true)).catch(() => {
      localStorage.removeItem("token");
      setAuthenticated(false);
      history.push("/login");
    });
  };

  const handleOnIdle = () => {
    eventBus.on("logout", (data) => {
      if (data?.expired) {
        setVisible(true);
      }

      localStorage.removeItem("clientId");
      localStorage.removeItem("token");

      setAuthenticated(false);
      history.push("/");
    });
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      isValidToken();
    }
    handleOnIdle();
  }, []);

  const authRouteRender = () => {
    if (!authenticated) {
      return (
        routes.map((route, i) => (
          <LoadRoute key={i} {...route} />
        ))
      );
    } else {
      return (
        userRoutesConcat.map((route, i) => (
          <LoadRoute key={i} {...route} />
        ))
      );
    }
  };

  const themeMode = theme === themeColor.LIGHT ? lightTheme : darkTheme;

  if (!mountedComponent) {
    return <div />;
  }

  return (
    <AppContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        user: getUserInfo(),
        theme: { themeName: theme, themeMode: themeMode, themeToggler: themeToggler },
        locale
      }}
    >
      <ThemeProvider theme={themeMode}>
        <HelmetProvider>
          <Helmet>
            <html lang={helmetHtmlByLang[lngFromUrl]} />
          </Helmet>
          <AutoLogoutModal
            visible={visible}
            setVisible={setVisible}
          />
          <Header />
          <Suspense fallback={<PageSpinner />}>
            <Switch>
              {authRouteRender()}
              <Redirect to="/page-not-found" />
            </Switch>
          </Suspense>
          <GlobalStyle />
        </HelmetProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
