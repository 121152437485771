const themeColor = {
  DARK: "dark",
  LIGHT: "light"
};

const defaultLanguage = "en";

const standardErrorsWay = "error.response.data.data.errors";

const supportedLanguages = {
  "en": {
    "url": "",
    "routePrefix": "",
    "regex": "\\/$|\\b",
    "description": "English"
  },
  "ua": {
    "url": "/ua",
    "routePrefix": "ua",
    "regex": "\\/ua\\b",
    "description": "Українська"
  },
  "ru": {
    "url": "/ru",
    "routePrefix": "ru",
    "regex": "\\/ru\\b",
    "description": "Русский"
  }
};

const helmetHtmlByLang = {
  "en": "en-US",
  "ua": "uk-UA",
  "ru": "ru-RU"
};

// You can get names and codes of statuses from https://httpstatuses.com/ and add status code to link
const responseStatus = {
  HTTP_OK: 200,
  HTTP_CREATED: 201,
  HTTP_NO_CONTENT: 204,
  HTTP_BAD_REQUEST: 400,
  HTTP_ERROR_VALIDATION: 422,
  FORBIDDEN: 403,
  HTTP_INTERNAL_SERVER_ERROR: 500,
  HTTP_UNAUTHORIZED: 401,
  HTTP_CONFLICT: 409
};

const roles = {
  ADMIN: "ROLE_ADMIN",
  MANAGER: "ROLE_MANAGER",
  CLIENT: "ROLE_CLIENT",
  SYSTEM: "ROLE_SYSTEM",
  SEO: "ROLE_SEO"
};

const arrayOperation = {
  FIRST: 0
};

const regex = {
  EMAIL: "^[_a-z0-9-]+([\\._a-z0-9-])*@[a-z-]+(\\.[a-z-])*(\\.[a-z]{2,})$",
  TEXT: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїєёА-ЯҐІЇЄЁ ]+$",
  NAME: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїєёА-ЯҐІЇЄЁ ]+$"
};

const minLength = 10;

const mercureTopicOrigin = "http://localhost";

const mercureUrl = new URL(`${window.location.origin}/.well-known/mercure`);

const timeConsts = {
  DAY: 86400000,
  NORMALIZER: 1000
};

const logs_levels = {
  INFO: "INFO",
  ERROR: "ERROR",
  WARNING: "WARNING"
};

const transactionStatus = {
  NEW: "NEW",
  FAIL: "FAIL",
  PENDING: "PENDING",
  PROCESSED: "PROCESSED",
};

const cardGateAttributesValue = {
  CARD_NUMBER: {
    title: "Card number",
    name: "cardNumber"
  },
  CARD_HOLDER: {
    title: "Cardholder name",
    name: "cardHolder"
  },
  EXPIRED_MONTH: {
    title: "Month",
    placeholder: "MM",
    name: "expiredMonth"
  },
  EXPIRED_YEAR: {
    title: "Year",
    placeholder: "YY",
    name: "expiredYear"
  },
  CVV_CODE: {
    title: "CVV/CVC",
    description: "Last 3 digits on the back of the card",
    name: "cvv"
  }
};

export {
  defaultLanguage,
  supportedLanguages,
  helmetHtmlByLang,
  themeColor,
  responseStatus,
  roles,
  regex,
  arrayOperation,
  mercureTopicOrigin,
  mercureUrl,
  minLength,
  timeConsts,
  logs_levels,
  transactionStatus,
  standardErrorsWay,
  cardGateAttributesValue
};
