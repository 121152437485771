import styled from "styled-components";
import { StyledContainer } from "../../styles/styledContainer";

export const StyledHeaderWrapper = styled.header`
  padding: 15px 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  @media screen and (max-width: 768px) {
    padding: 10px 0;
  }
`;

export const StyledHeaderContainer = styled(StyledContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
  .header-left,
  .header-right {
    max-width: 300px;
    width: 100%;
    display: inline-flex;
    align-items: center;
  }
  .header-right {
    justify-content: flex-end;
  }
  
  @media screen and (max-width: 992px) {
    .header-left,
    .header-right {
      max-width: 90px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .header-left {
      order: 2;
    }
    .header-right {
      order: 3;
    }
  }
`;