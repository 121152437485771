import styled from "styled-components";

const size = {
  size: ''
}

const changeContainerSize = (size) => {
  switch (size) {
    case 'xl':
      return `
        max-width: 1920px
      `
    case 'sm':
      return `
        max-width: 1170px
      `
    default:
      return `
        max-width: 1470px
      `
  }
}

export const StyledContainer = styled("div", size)`
  ${({ size }) => changeContainerSize(size)};
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  background-color: ${({theme}) => theme.containerBackgroundColor};
  border-left: 1px solid ${({theme}) => theme.mainBorderColor};
  border-right: 1px solid ${({theme}) => theme.mainBorderColor};
  ${({ wrapper }) => wrapper !== "content" && `min-height: calc(100vh - 85px)`};
  ${({ wrapper }) => wrapper !== "content" && `padding-bottom: 30px`};
  @media screen and (max-width: 992px) {
    ${({ wrapper }) => wrapper !== "content" && `height: auto`};
    ${({ wrapper }) => wrapper !== "content" && `padding-bottom: 50px`};
  }
`;


export const StyledContentWrapper = styled.div`
  padding-top: 50px;
`;
