export default {
  bodyBackgroundColor: "#191919",
  containerBackgroundColor: "#111111",
  text: "#FFFFFF",
  mainColor: "#c8b19b",
  mainColorDarker: "#a99581",
  mainBorderColor: "#000000",
  neutralPrimary: "#7e7e7e",
  switchPosition: "calc(100% - 19px)",
  input: {
    labelColor: "#A9A9A9",
    backgroundColor: "#191919",
    borderColor: "1px solid rgba(1, 1, 1, 1)"
  },
  select: {
    labelColor: "#A9A9A9",
    backgroundColor: "#191919",
    borderColor: "#111111",
    backgroundColorSelected: "#a99581",
  },
  datePicker: {
    labelColor: "#A9A9A9",
    backgroundColor: "#191919",
    borderColor: "#111111",
    navigationBackgroundColor: "#111111",
    currentDateTextColor: "#FFFFFF",
  },
};