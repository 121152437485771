import { useEffect, useState } from "react";
import { themeColor } from "./consts";

export const useDarkMode = () => {
  const [theme, setTheme] = useState(themeColor.LIGHT);
  const [mountedComponent, setMountedComponent] = useState(false);

  const setMode = (mode) => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };

  const themeToggler = () => {
    theme === themeColor.LIGHT ? setMode(themeColor.DARK) : setMode(themeColor.LIGHT);
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    const colorScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

    if (localTheme === null && colorScheme) {
      setTheme(themeColor.DARK);
    } else {
      localTheme ? setTheme(localTheme) : setTheme(themeColor.LIGHT);
    }
    setMountedComponent(true);
  }, []);

  return [theme, themeToggler, mountedComponent];
};