import styled from "styled-components";

const colorButton = {
  color: String,
  border: Boolean
};

const styleButton = (color, border, theme) => {
  switch (color) {
    case "main":
      return `
        color: ${border ? theme.mainColor: '#fff'};
        background-color: ${border ? 'transparent': theme.mainColor};
        border: 2px solid ${theme.mainColor};
        &:hover {
          background-color: ${border ? 'rgba(130, 160, 130, 0.15)' : theme.mainColorDarker};
          border: 2px solid ${theme.mainColorDarker};
        }
    `;
    case "neutral":
      return `
        color: ${border ? '#323233': '#fff'};
        background-color: ${border ? 'transparent': '#323233'};
        border: 2px solid #323233;
        &:hover {
          background-color: ${border ? 'rgba(55, 55, 55, 0.15);': '#4e4e4e'};
          border: 2px solid #4e4e4e;
        }
    `;
    case "success":
      return `
        color: ${border ? '#43c862': '#fff'};
        background-color: ${border ? 'transparent': '#43c862'};
        border: 2px solid #43c862;
        &:hover {
          background-color: ${border ? 'rgba(46, 210, 38, 0.1);': '#34d65a'};
          border: 2px solid #34d65a;
        }
     `;
    case "danger":
      return `
        color: ${border ? '#f44336': '#fff'};
        background-color: ${border ? 'transparent': '#f44336'};
        border: 2px solid #f44336;
        &:hover {
          background-color: ${border ? 'rgba(255, 77, 89, 0.1);': '#fe2c1d'};
          border: 2px solid #fe2c1d;
        }
      `;
    case "warning":
      return `
        color: #202020;
        background-color: ${border ? 'transparent': '#ffeb3f'};
        border: 2px solid #ffeb3f;
        &:hover {
          background-color: ${border ? 'rgba(254, 217, 126, 0.2);': '#ffe23f'};
          border: 2px solid #ffe23f;
        }
     `;
    case "info":
      return `
        color: ${border ? '#3286d9': '#fff'};
        background-color: ${border ? 'transparent': '#3286d9'};
        border: 2px solid #3286d9;
        &:hover {
          background-color: ${border ? 'rgba(1, 125, 225, 0.1);': '#379cff'};
          border: 2px solid #379cff;
        }
     `;
    case "white":
      return `
        color: ${border ? '#fff': '#656565'};
        background-color: ${border ? 'transparent': '#fff'};
        border: 2px solid #fff;
        &:hover {
          background-color: ${border ? 'rgba(255, 255, 255, 0.15);': '#fff'};
          border: 2px solid #fff;
        }
      `;
    default:
      return `
        color: #323233;
        background: transparent;
        border: 2px solid #323233;
        &:hover {
          background-color: rgba(55, 55, 55, 0.15);
          border: 2px solid #4e4e4e;
        }
      `;
  }
};

export const StyledButton = styled("button", colorButton)`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ width }) => width && `width: ${width}%`};
  ${({ size }) => size && `font-size: ${size}px`};
  padding:  ${({ figure }) => figure === "circle" ? "12px" : "8px 16px"};
  font-weight: ${({ weight }) => weight || "500"};
  text-align: center;
  vertical-align: middle;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ figure }) => figure === "circle" ? "50%" : "5px"};
  outline: none;
  cursor: pointer;
  transition: all .3s ease;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
    transform: scale(1) !important;
  `};
  ${({ color, border, theme }) => styleButton(color, border, theme)}
  &:active {
    transform: scale(0.948) translateZ(0);
  }
`;