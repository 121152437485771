import React, { lazy } from "react";
import { supportedLanguages } from "../utils/consts";

const HomePage = lazy(() => import("../pages/home/HomePage"));
const LoginPage = lazy(() => import("../pages/login/LoginPage"));
const RegistrationPage = lazy(() => import("../pages/registration/RegistrationPage"));
const ExamplePage = lazy(() => import("../pages/example/ExamplePage"));
const EmailConfirm = lazy(() => import("../pages/emailConfirm/EmailConfirmPage"));
const ForgotPassword = lazy(() => import("../pages/forgotPassword/ForgotPasswordPage"));
const ChangePassword = lazy(() => import("../pages/changePassword/ChangePasswordPage"));
const CardGateForm = lazy(() => import("../pages/cardGate/CardGateFormPage"));

let locale = "";

Object.entries(supportedLanguages).map(([key, language]) => {
  locale = `${locale}${language.routePrefix}|`
});

const routes = [
  {
    title: "Home page",
    path: `/:locale(${locale})/`,
    exact: true,
    component: HomePage
  },
  {
    title: "Login page",
    path: `/:locale(${locale})?/login`,
    exact: false,
    component: LoginPage
  },
  {
    title: "Registration page",
    path: `/:locale(${locale})?/registration`,
    exact: false,
    component: RegistrationPage
  },
  {
    title: "Example page",
    path: `/:locale(${locale})?/example-components`,
    exact: false,
    component: ExamplePage
  },
  {
    title: "Email Confirm",
    path: `/:locale(${locale})?/email-confirm/:token?`,
    component: EmailConfirm
  },
  {
    title: "Forgot Password",
    path: `/:locale(${locale})?/remind`,
    component: ForgotPassword
  },
  {
    title: "Change Password",
    path: `/:locale(${locale})?/change-password/:token?`,
    component: ChangePassword
  },
  {
    title: "CardGateForm",
    path: `/:locale(${locale})?/card-gate/:token?`,
    component: CardGateForm
  }
];

export default routes;