import { account, clients, goods, navbar } from "./rbac-consts";

const rules = {
  ROLE_CLIENT: {
    static: [
      //navbar rules
      navbar.GOODS,
      navbar.ACCOUNT,
      //other
      goods.CLIENT,
      account.CLIENT,
    ],
    dynamic: {}
  },
  ROLE_ADMIN: {
    static: [
      //navbar rules
      navbar.CLIENTS,
      navbar.GOODS,
      clients.ADMIN,
      goods.ADMIN
    ],
    dynamic: {}
  }
};

export default rules;
