import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { AppContext } from "../../../App";

const CustomLink = ({ children, to, className, activeClassName, onClick }) => {
  const { locale } = useContext(AppContext);

  return (
    <NavLink to={`${locale}${to}`} className={className} activeClassName={activeClassName} onClick={onClick}>
      {children}
    </NavLink>
  );
};

export default CustomLink;