import React, { useEffect, useState } from "react";
import CustomLink from "../customLink/CustomLink";
import { useTranslation } from "react-i18next";

import {
  StyledMenuList,
  StyledMobileNavigation,
  StyledNavigationContent,
  StyledNavigationWrapper
} from "./styledNavigation";

const Navigation = () => {
  const { t, ready } = useTranslation("navigation", { useSuspense: false });
  const [show, setShow] = useState(false);

  const blockedBody = () => {
    if (show) {
      return document.body.style.cssText = `
        touch-action: none;
        overflow: hidden;
      `;
    }

    document.body.removeAttribute("style");
  };

  useEffect(() => {
    blockedBody();
  }, [show]);

  const toggleMenu = () => {
    setShow(!show);
  };

  const closeMenu = () => {
    setShow(false);
  };

  if (!ready) {
    return null;
  }

  return (
    <StyledNavigationWrapper>
      <StyledMobileNavigation show={show} onClick={toggleMenu}>
        <div className="navbar-top" />
        <div className="navbar-middle" />
        <div className="navbar-bottom" />
      </StyledMobileNavigation>
      <StyledNavigationContent show={show}>
        <StyledMenuList show={show}>
          <li className="menu-item">
            <CustomLink to="/" onClick={closeMenu}>
              {t("home")}
            </CustomLink>
          </li>
          <li>
            <CustomLink to="/example-components" onClick={closeMenu}>
              {t("components")}
            </CustomLink>
          </li>
        </StyledMenuList>
      </StyledNavigationContent>
    </StyledNavigationWrapper>
  );
};

export default Navigation;