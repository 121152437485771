import styled from "styled-components";

export const StyledNavigationWrapper = styled.nav`
  flex-grow: 1;
  @media screen and (max-width: 576px) {
    max-width: 90px;
    width: 100%;
    order: 1;
    flex-grow: inherit;
  }
`;

export const StyledNavigationContent = styled.div`
  @media screen and (max-width: 576px) {
    width: 100%;
    height: 0;
    max-height: none;
    background-color: #232323;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    overflow-y: hidden;
    transition: background .44s .2s cubic-bezier(0.52, 0.16, 0.24, 1),height .5s cubic-bezier(0.52, 0.16, 0.24, 1);
    ${({show}) => show && show && `
        height: 100vh;
        transition: background .36s cubic-bezier(0.32, 0.08, 0.24, 1),height .5s cubic-bezier(0.52, 0.16, 0.24, 1);
    `};
  }
`;

export const StyledMenuList = styled.ul`
  display: flex;
  justify-content: center;
  li {
    padding: 0 12px;
    display: inline-flex;
    align-items: center;
    transition: all .3s ease;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.mainColor};
    }
  }
  @media screen and (max-width: 576px) {
    height: 100vh;
    flex-direction: column;
    li {
      padding: 10px 25px;
      text-align: center;
      justify-content: center;
      a {
        padding: 0 0 10px 0;
        width: 100%;
        display: inline-flex;
        justify-content: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      }
    }
  }
`;

export const StyledMobileNavigation = styled.div`
  width: 25px;
  height: 25px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none;
  z-index: 5;
  div {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #ffffff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
  .navbar-top {
    top: 3px;
  }
  .navbar-middle {
    top: 12px;
  }
  .navbar-bottom {
    top: 21px;
  }
  ${({show}) => show && show && `
    .navbar-top {
      top: 12px;
      transform: rotate(135deg);
    }
    .navbar-middle {
      opacity: 0;
      left: -60px;
    }
    .navbar-bottom {
      top: 12px;
      transform: rotate(-135deg);
    }
  `};
  
  @media screen and (max-width: 576px) {
    display: block;
  }
`;