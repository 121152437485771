import styled from "styled-components";

export const StyledLogoWrapper = styled.div`
  height: 100%;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;
  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
    transition: all .3s ease;
  }
  @media screen and (max-width: 992px) {
    width: 85px;
  }
`;