const navbar = {
  ACCOUNT: "account:sidebar",
  CLIENTS: "clients:sidebar",
  GOODS: "goods:sidebar"
};

const clients = {
  ADMIN: "clients:admin"
}

const goods = {
  ADMIN: "goods:admin",
  CLIENT: "goods:client"
};

const account = {
  CLIENT: "account:client",
};

export {
  navbar,
  clients,
  goods,
  account
};
