import React from "react";
import SidebarItem from "./SidebarItem";
import CustomLink from "../customLink/CustomLink";

import sidebarRoutes from "./sidebarRoutes";

const SidebarLinkList = ({ role, handleChange }) => {

  return (
    <React.Fragment>
      {sidebarRoutes.map((rout) => {
        return (
          <SidebarItem
            key={rout.title}
            role={role}
            perform={rout.perform}
            as={CustomLink}
            to={rout.path}
            icon={rout.icon}
            title={rout.title}
            handleChange={handleChange}
          />
        );
      })}
    </React.Fragment>
  );
};

export default SidebarLinkList;