export default {
  bodyBackgroundColor: "#f9f8f6",
  containerBackgroundColor: "#FFFFFF",
  text: "#656565",
  mainColor: "#c8b19b",
  mainColorDarker: "#a99581",
  mainBorderColor: "#EFE6DD",
  neutralPrimary: "#afafaf",
  switchPosition: "1px",
  input: {
    labelColor: "#656565",
    backgroundColor: "#FFFFFF",
    borderColor: "1px solid rgba(235, 235, 235, 1)"
  },
  select: {
    labelColor: "#656565",
    backgroundColor: "#FFFFFF",
    borderColor: "#E5E5E5",
    backgroundColorSelected: "#a99581",
  },
  datePicker: {
    labelColor: "#656565",
    backgroundColor: "#FFFFFF",
    borderColor: "#E5E5E5",
    navigationBackgroundColor: "#323233",
    currentDateTextColor: "#FFFFFF",
  },
};