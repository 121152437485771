import React from "react";
import Logo from "../logo/Logo";
import Navigation from "../navigation/Navigation";
import SidebarContainer from "../sidebar/SidebarContainer";

import { StyledHeaderContainer, StyledHeaderWrapper } from "./styledHeader";

const Header = () => {
  return (
    <StyledHeaderWrapper>
      <StyledHeaderContainer wrapper="content">
        <div className="header-left">
          <Logo />
        </div>
        <Navigation />
        <div className="header-right">
          <SidebarContainer />
          {/*<LanguageSelect/>*/}
        </div>
      </StyledHeaderContainer>
    </StyledHeaderWrapper>
  );
};

export default Header;