import React, { useContext, useState } from "react";
import CustomLink from "../customLink/CustomLink";
import Drawer from "rc-drawer";
import SidebarLinkList from "./SidebarLinkList";
import { useTranslation } from "react-i18next";

import eventBus from "../../../utils/eventBus";
import { AppContext } from "../../../App";

import { StyledButton } from "../../styles/styledButton";
import {
  StyledAuthButtons, StyledSeparator,
  StyledSidebar,
  StyledSidebarAction,
  StyledSidebarItem,
  StyledSidebarLink,
  StyledSidebarWrapper
} from "./styledSidebar";

const SidebarContainer = () => {

  const { t, ready } = useTranslation("sidebar", { useSuspense: false });
  const { user } = useContext(AppContext);

  const [visible, setVisible] = useState(false);

  const showSidebar = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  if (!ready) {
    return null;
  }

  if (!user) {
    return (
      <StyledAuthButtons>
        <StyledButton
          color="main"
          border="true"
          as={CustomLink}
          to="/login"
          className="login-button"
        >
          <span className="login-button__icon icon-account" />
          <span className="login-button__text">{t("login")}</span>
        </StyledButton>
        <StyledButton
          color="main"
          as={CustomLink}
          to="/registration"
          className="registration-button"
        >
          {t("registration")}
        </StyledButton>
      </StyledAuthButtons>
    );
  }

  return (
    <StyledSidebarWrapper>
      <StyledSidebarAction>
        <button
          title="Profile"
          onClick={showSidebar}
        >
          <span className="icon-account"/>
        </button>
      </StyledSidebarAction>
      <Drawer
        width="320"
        placement="right"
        handler={false}
        open={visible}
        onClose={onClose}
      >
        <StyledSidebar>
          <SidebarLinkList
            handleChange={onClose}
            role={user.roles}
          />
          <StyledSeparator/>
          <StyledSidebarItem>
            <StyledSidebarLink
              as="button"
              className="sidebar-link"
              onClick={() => {
                onClose();
                eventBus.dispatch("logout");
              }}
            >
              <div className="sidebar-link__icon">
                <span className="icon-sign-out" />
              </div>
              <div className="sidebar-link__title">
                {t("logout")}
              </div>
            </StyledSidebarLink>
          </StyledSidebarItem>
        </StyledSidebar>
      </Drawer>
    </StyledSidebarWrapper>
  );
};

export default SidebarContainer;