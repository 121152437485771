import React, { lazy } from "react";
import routes from "./routes";
import { supportedLanguages } from "../utils/consts";

const AccountSettings = lazy(() => import("../pages/account/AccountSettingsPage"));

let locale = "";

Object.entries(supportedLanguages).map(([key, language]) => {
  locale = `${locale}${language.routePrefix}|`;
});

const userRoutes = [
  {
    title: "Account page",
    path: `/:locale(${locale})?/panel/account`,
    component: AccountSettings
  }
];
const userRoutesConcat = userRoutes.concat(routes);

export default userRoutesConcat;